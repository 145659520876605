<template>
  <div>
    <CRow v-if="view == 'table'">
      <CCol lg="12" md="12" sm="12" xs="12">
        <CButton
          size="sm"
          color="info"
          @click="mtd_set_document_proceso"
        >
          <i class="fas fa-save"></i> Emitir documento de un proceso
        </CButton>
      </CCol>
      <hr>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de lotes para venta"
          icon="fas fa-cash-register"
          btn_name="caja"
          :size="'md'"
          :button_new="false"
          :actions="true"
          :withActions="'6%'"
          :buttonEdit="false"
          :buttonDelete="false"
          :myButtons="myButtons"
          @mtd_set_document="mtd_set_document"
          @mtd_view_detail="mtd_view_detail"
        />
      </CCol>
    </CRow>
    <CRow v-else>
      <CCol lg="6" sm="6" md="12" xs="12" class="text-left">
        <CButton
          size="sm"
          color="warning"
          @click="mtd_return_table"
        >
          <i class="fas fa-angle-left"></i> Regresar
        </CButton>
      </CCol>
      <CCol lg="6" sm="6" md="12" xs="12" class="text-right">
        <CButton
          size="sm"
          color="info"
          v-c-tooltip.hover="{
            content: 'Registrar documento',
          }"
          @click="mtd_save"
          :disabled="cp_disabled_save"
        >
          <i class="fas fa-save"></i> Registrar
        </CButton>
      </CCol>
      <CCol class="mt-2" lg="12" sm="12" md="12" xs="12">
        <CCard>
          <CCardHeader>
            <strong> DOCUMENTO</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="4" md="4" sm="6" xs="12" v-if="document.asignation == 2">
                <CInput
                  label="Detalle"
                  placeholder="Detalle"
                  :disabled="true"
                  v-model="document.detalle"
                />
              </CCol>
              <CCol lg="4" md="4" sm="6" xs="12" v-else>
                <label for="">DETALLE</label>
                <v-select
                  :options="data_stock"
                  v-model="document.detalle"
                  placeholder="Seleccione"
                  @input="mtd_change_stock"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Kilos brutos"
                  placeholder="Kilos brutos"
                  :disabled="true"
                  v-model="document.bruto"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Diferencia"
                  placeholder="Diferencia"
                  :disabled="true"
                  v-model="document.diferencia"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Kilos neto"
                  placeholder="Kilos neto"
                  :disabled="true"
                  v-model="document.neto"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Cantidad a vender"
                  placeholder="Cantidad"
                  v-model="document.amount"
                  :disabled="document.asignation == 2?true:false"
                  @blur="mtd_out_focus"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <label for="">Elegir Moneda</label>
                <v-select
                  :options="data_current"
                  v-model="document.current"
                  placeholder="Seleccione"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Fecha"
                  placeholder="Fecha"
                  v-model="document.date"
                  type="date"
                />
              </CCol>
              <CCol lg="4" md="4" sm="6" xs="12">
                <label for="">Elegir cliente</label>
                <v-select
                  :options="data_client"
                  v-model="document.client"
                  placeholder="Seleccione"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <label for="">Comprobante</label>
                <v-select
                  :options="data_typedocument"
                  v-model="document.typedocument"
                  placeholder="Seleccione"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="N° Comprobante"
                  placeholder="N° Comprobante"
                  v-model="document.num_comprobante"
                  :disabled="false"
                />
              </CCol>
              <!-- :disabled="document.typedocument != null? (document.typedocument.value==1?false:true) :true" -->
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="KGxQQ"
                  placeholder="KGxQQ"
                  v-model="document.kgxqq"
                  @blur="mtd_out_focus"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="QQ Netos"
                  placeholder="QQ Netos"
                  v-model="cp_qqnetos"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Prec. QQ"
                  placeholder="Prec. QQ"
                  v-model="document.precio_qq"
                  @blur="mtd_out_focus"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Prec. uni"
                  placeholder="Prec. uni"
                  v-model="cp_precio_uni"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Total"
                  placeholder="Total"
                  v-model="cp_total_sintc"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput label="TC" placeholder="TC" v-model="document.tc" />
              </CCol>
              <!-- :disabled="document.current == null? true:(document.current.value == 'SOLES'?true:false)" -->
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Total soles"
                  placeholder="Total soles"
                  v-model="cp_total_contc"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="10" md="10" sm="6" xs="12">
                <CInput
                  label="Descripción para el documento"
                  placeholder="Descripción para el documento"
                  v-model="document.description"
                />
              </CCol>
              
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- modal view -->
    <cModalViewDetLot
      title="Lista de pagos"
      :boo_modal="modal_view.boo"
      :item="modal_view.item"
      @close_view="close_view"
    ></cModalViewDetLot>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import cModalViewDetLot from '../../components/shared/sale/cModalViewDetLot.vue';

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "name_asignation", label: "Asignación", _style: "width:20%;" },
  { key: "detalle", label: "Detalle", _style: "width:20%;" },
  { key: "amount", label: "Peso neto", _style: "width:20%;" },
  { key: "date", label: "Fecha", _style: "width:20%;" },
  { key: "responsable", label: "Responsable", _style: "width:20%;" },
  { key: "destination", label: "Destino", _style: "width:20%;" },
  { key: "status", label: "Estado", _style: "width:20%;" },
];

export default {
  name: "v-sale-document",
  components: { CTableWrapper, vSelect,cModalViewDetLot },
  data() {
    return {
      prefix: "lote/document",
      view: "table",
      fields,
      data: [],
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Registrar documento",
          action: "mtd_set_document",
          icon: "fas fa-table",
          show:{
            4:true,
            5:false
          }
        },
        {
          class: "btn-sm",
          color: "info",
          tooltip: "Ver Detalle",
          action: "mtd_view_detail",
          icon: "fas fa-eye",
        },
      ],

      data_current: [
        { value: "SOLES", label: "SOL" },
        { value: "DOLARES", label: "DOLAR" },
      ],
      data_client: [],
      data_stock: [],
      data_typedocument: [
        { value: 1, label: "RECIBO EGRESOS" },
        { value: 2, label: "BOLETA" },
        { value: 3, label: "FACTURA" },
      ],
      details: [],
      document: {
        asignation:"",
        lot_id: "",
        detalle_id:"",
        detalle: "",
        description:"",
        bruto: "",
        diferencia: "",
        neto: "",
        current: null,
        date: null,
        client: null,
        typedocument: null,
        num_comprobante: "",
        kgxqq: "",
        qqnetos: "",
        precio_uni: "",
        precio_qq: "",
        total: "",
        tc: "",
        total_tc: "",
        amount:""
      },
      det_table_view:[],
      modal_view: {
        boo: false,
        item: [],
      },
    };
  },
  computed: {
    cp_disabled_save: function () {
      if (
        this.document.current != null &&
        this.document.date != null &&
        this.document.client != null &&
        this.document.typedocument != null &&
        this.document.kgxqq > 0 &&
        this.document.precio_qq > 0
      )
        return false;
      return true;
    },
    cp_qqnetos: function () {
      let res = 0;
      if (this.document.kgxqq > 0) {
        if (this.document.asignation==2) {
          res = parseFloat(this.document.neto) / parseFloat(this.document.kgxqq);  
        }else{
          res = parseFloat(this.document.amount) / parseFloat(this.document.kgxqq);
        }
        
      }
      this.document.qqnetos = parseFloat(res).toFixed(2);
      return this.document.qqnetos;
    },
    cp_total_sintc: function () {
      let res = 0;
      if (this.document.qqnetos > 0 && this.document.precio_qq > 0) {
        res =
          parseFloat(this.document.qqnetos) *
          parseFloat(this.document.precio_qq);
      }
      this.document.total = parseFloat(res).toFixed(2);
      return this.document.total;
    },
    cp_precio_uni: function () {
      let res = 0;
      if (this.document.total > 0 && this.document.neto) {
        if (this.document.asignation==2){
          res = parseFloat(this.document.total) / parseFloat(this.document.neto);
        }else{
          res = parseFloat(this.document.total) / parseFloat(this.document.amount);
        }
      }
      this.document.precio_uni = parseFloat(res).toFixed(7);
      return this.document.precio_uni;
    },
    // cp_tipo_cambio: function(){
    //   // let res = 1;
    //   // if (this.document.current != null) {
    //   //   if (this.document.current.value == 'SOLES') {
    //   //     res = 1;
    //   //   }else{
    //   //     res = "";
    //   //   }
    //   // }else{
    //   //   res=1;
    //   // }
    //   // this.document.tc=res;
    //   // return this.document.tc;
    // },
    cp_total_contc: function () {
      let res = 0;
      if (this.document.total > 0 && this.document.tc > 0) {
        res = parseFloat(this.document.total) * parseFloat(this.document.tc);
      }
      this.document.total_tc = parseFloat(res);
      return this.document.total_tc;
    },
  },
  created() {
    this.mtd_getData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getData: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data;
          this.data_client = response.data_client;
          this.data_stock= response.data_stock;
        })
        .catch((errors) => {});
    },
    mtd_set_document: function (item) {
      this.view = "document";
      this.details = item.detail;
      let bruto = 0;
      this.details.forEach((element) => {
        bruto += parseFloat(element.peso_bruto);
      });
      this.document.detalle = item.detalle;
      this.document.bruto = bruto;
      this.document.neto = item.amount;
      this.document.diferencia = parseFloat(bruto - item.amount).toFixed(2);
      this.document.lot_id = item.Id;
      this.document.detalle_id = item.detalle_id;
      this.document.asignation = item.asignation;
      this.document.amount=null;
    },
    mtd_set_document_proceso: function () {
      this.view = "document";
      this.details = [];
      this.document.detalle = null;
      this.document.bruto = 0.0;
      this.document.neto = 0.0;
      this.document.diferencia = 0.0;
      this.document.lot_id = null;
      this.document.detalle_id = null;
      this.document.asignation = 3;
    },
    mtd_return_table: function () {
      this.view = "table";
      this.details = [];
      this.document = {
        asignation:"",
        lot_id: "",
        detalle_id:"",
        detalle: "",
        description:"",
        bruto: "",
        diferencia: "",
        neto: "",
        current: null,
        date: null,
        client: null,
        typedocument: null,
        kgxqq: "",
        qqnetos: "",
        precio_uni: "",
        precio_qq: "",
        total: "",
        tc: "",
        total_tc: "",
        amount:""
      };
    },
    mtd_out_focus: function () {
      this.document.kgxqq =
        this.document.kgxqq > 0
          ? parseFloat(this.document.kgxqq).toFixed(2)
          : 0.0;
      this.document.qqnetos =
        this.document.qqnetos > 0
          ? parseFloat(this.document.qqnetos).toFixed(2)
          : 0.0;
      this.document.precio_uni =
        this.document.precio_uni > 0
          ? parseFloat(this.document.precio_uni).toFixed(2)
          : 0.0;
      this.document.precio_qq =
        this.document.precio_qq > 0
          ? parseFloat(this.document.precio_qq).toFixed(5)
          : 0.0;
      this.document.total =
        this.document.total > 0
          ? parseFloat(this.document.total).toFixed(2)
          : 0.0;
      this.document.tc =
        this.document.tc > 0 ? parseFloat(this.document.tc).toFixed(2) : 0.0;
      this.document.total_tc =
        this.document.total_tc > 0
          ? parseFloat(this.document.total_tc).toFixed(2)
          : 0.0;
      this.document.amount =
        this.document.amount > 0
          ? parseFloat(this.document.amount).toFixed(2)
          : 0.0;
      if (parseFloat(this.document.amount) > parseFloat(this.document.neto)) {        
        bus.$emit("alert", {
          color: "warning",
          message: "EL MONTO NO DEBE SER MAYOR AL STOCK NETOS",
        });
        this.document.amount = "";
      }else{
        if (parseFloat(this.document.amount)==0) {
          bus.$emit("alert", {
            color: "warning",
            message: "EL MONTO NO DEBE SER CERO",
          });
          this.document.amount="";
        }
      }
    },
    mtd_save: function () {
      this.document.total = parseFloat(this.document.total);
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix+"/store",
        token: this.$store.getters.get__token,
        params: this.document,
      })
        .then((response) => {
          if (response.state == 0) {
            this.mtd_return_table();
            this.mtd_getData();
            bus.$emit("alert", {
              color: "success",
              message: "DOCUMENTO EMITIDO CORRECTAMENTE!!",
            });
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_view_detail: function(item){
      this.det_table_view=[];
      item.detail.forEach(element => {
        this.det_table_view.push({
          'supplier': element.acopio_movement.acopio_detail.acopio.proveedor.last_name+ " "+element.acopio_movement.acopio_detail.acopio.proveedor.name,
          'ticket': element.acopio_movement.acopio_detail.ticket,
          'neto':element.peso_neto,
          'bruto': element.peso_bruto,
          'saco':element.saco
        });
      });
      this.modal_view.boo = true;
      this.modal_view.item = this.det_table_view;
    },
    close_view: function(){
      this.det_table_view=[];
      this.modal_view = {
        boo: false,
        item: [],
      };
    },
    mtd_change_stock: function(){
      this.document.detalle_id=this.document.detalle.value;
      this.document.neto=this.document.detalle.stock;
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
