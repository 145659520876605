<template>
  <CModal
    :show.sync="boo_modal"
    :no-close-on-backdrop="true"
    :centered="false"
    title="Modal title 2"
    :size="size"
    color="info"
  >
    <div class="row" v-if="item != undefined">
      <CCol lg="12" md="12" sm="12" xs="12">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Ticket</th>
              <th>Socio</th>
              <th>P.Neto</th>
              <th>P.Bruto</th>
              <th>N.Sacos</th>
            </tr>
          </thead>

          <tbody v-if="item.length > 0">
            <tr v-for="(it, index) in item" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ it.ticket }}</td>
              <td>{{ it.supplier }}</td>
              <td>{{ it.neto }}</td>
              <td>{{ it.bruto }}</td>
              <td>{{ it.saco }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="6">
                <slot name="no-items-view">
                  <div class="text-center">
                    <h2>No hay registros para mostrar</h2>
                  </div>
                </slot>
              </td>
            </tr>
          </tbody>
        </table>
      </CCol>
    </div>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="danger">Cancelar</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "c-modal-view",
  data() {
    return {};
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {},
    size: String,
  },
  methods: {
    mtd_close() {
      this.$emit("close_view");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>